import {AComponent} from "../abstract/AComponent";

export class NaturalForm extends AComponent {
    initialize(params) {
        super.initialize(params);

        this.events = {
            'submit' : 'onSubmit',
            'change .select1' : 'onChangeSelect'
        }
    }
    onSubmit(e) {
        e.preventDefault();

        /*
        if(this.$refs.select[1].value && this.$refs.select[1].value.length) {
            window.location.href = this.$refs.select[1].getHref();
        }
        */
    }

    bind() {
        super.bind();
        this.onChangeSelect = this.onChangeSelect.bind(this);
        //this.$refs.select[0].on('change',this.onChangeSelect);
        //this.$refs.select[1].changeOptionsshow(this.$refs.select[0].value);
    }

    unbind() {
        super.unbind();
        //this.$refs.select[0].off('change',this.onChangeSelect)
    }

    onChangeSelect(e) {
        //this.$refs.select[1].changeOptionsshow(e);
    }

}