import {AComponent} from "./AComponent";

export class AScrollableElmt extends AComponent {
    initialize(params) {
        this.top = 0;
        this.height = 0;
        this.wH = F._.wH;

        this.deltaWillAppear = this.deltaWillAppear !== undefined ? this.deltaWillAppear : 500;

        this.isShown = false;
        this.willAppeared = false;
        this.willAlreadyAppeared = false;

        super.initialize(params);

    }
    bind() {
        super.bind();

        this.isShown = false;
        this.willAppeared = false;
        this.willAlreadyAppeared = false;
    }
    willAppear() {
        super.willAppear();

        this.scroll(F._.scrollTop, F._.scrollTop - 1);
    }
    resize(wW, wH) {
        super.resize(wW, wH);
        this.bounds = this.$el.getBoundingClientRect();


        this.top = this.bounds.top + F._.scrollTop;
        this.height = this.bounds.height;

        this.wH = wH;
    }
    scroll(scrollTop, lastScrollTop) {
        super.scroll(scrollTop, lastScrollTop);
        if(!this.bounds.width || !this.bounds.height) {
            return;
        }
        if(this.top  < scrollTop + this.deltaWillAppear + this.wH) {
            if(!this.willAppeared) {
                this.willAppearInScroll();
            }
        } else {
            this.willAppeared = false;
        }

        if(this.top < scrollTop + this.wH && this.top + this.height > scrollTop) {
            this.scrollInViewport(scrollTop, lastScrollTop)
			this.isShown = true;
        } else {
			this.isShown = false;
        }
    }
    scrollInViewport(scrollTop, lastScrollTop) {

    }
    willAppearInScroll() {
        if(!this.willAlreadyAppeared) {
            this.willAppearForFirstTimeInScroll();
        }
        this.willAppeared = true;
        this.willAlreadyAppeared = true;
    }
    willAppearForFirstTimeInScroll() {

    }


}