import './import';
import './classes/utils/polyfill.js';
import 'promise-polyfill/src/polyfill'
import {Render} from "./classes/utils/Render";
import {Resizable} from "./classes/utils/Resizable";
import {delegateEventListener} from "./vendor/event-delegation";
import {AComponent} from "./classes/abstract/AComponent";
import {getScrollbarWidth} from "./classes/utils/getScrollbarWidth";

import { gsap } from "gsap";
import { ExpoScaleEase, RoughEase, SlowMo } from "gsap/EasePack";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { SplitText } from "gsap/SplitText";

import './initializers/cookie-consent'
import './gmaps'

gsap.registerPlugin(ScrollTrigger, ExpoScaleEase, RoughEase, SlowMo, MotionPathPlugin, SplitText);


if(!window.console || !window.console.log) {
    window.console = {log : function(){}, warn : function() {}}
}

console.log('%c KLAXIT RECRUTE ', 'background: #000; color: #fff; font:bold 40px/40px impact, sans-serif;');
console.log('https://klaxit.welcomekit.co/')

/**
 * écouteur scroll/resize/load
 * + RAF
 */
F._.wW = window.innerWidth;
F._.wH = window.innerHeight;
F._.scrollTop = window.pageYOffset;

F.Render = new Render();
F.Resizable = new Resizable();

let resizableElmts = [],
    scrollableElmts = [],
    lastScrollTop = 0,
    scrollTop = 0;
window.addEventListener('scroll', () => {
    scrollTop = window.pageYOffset;
    F._.scrollTop = scrollTop;
});

window.addEventListener('resize', () => {
    F._.wW = window.innerWidth;
    F._.wH = window.innerHeight;
    for (let i = 0, e = null; e = resizableElmts[i]; i++) {
        e.resize && e.resize(F._.wW, F._.wH);
    }
    F.Resizable.resize();
});

window.addEventListener('load', () => {
    F.Resizable.resize();
});

requestAnimationFrame(function tick() {
    F.Render.update();
    if (lastScrollTop !== scrollTop) {
        F._.scrollTop = scrollTop;
        for (let i = 0, e = null; e = scrollableElmts[i]; i++) {
            e.scroll && e.scroll(scrollTop, lastScrollTop);
        }
        lastScrollTop = scrollTop;
    }

    requestAnimationFrame(tick);
});

/**
 * instancie un acomponent sur le body pour créer tout les components présents dans le DOM
 */
let bodyView = new AComponent({el : document.body});
bodyView.render();

bodyView.bind();
bodyView.willAppear();
bodyView.didAppear();
scrollableElmts.push(bodyView);



delegateEventListener(document.body, 'click', '.trigger-js', e => {
    e.preventDefault();
    const el = e.currentTarget;
    if(e.currentTarget.classList.contains('open-popin')) {
        e.stopPropagation();
        let content = null;
        if(el.getAttribute('data-content')[0] === '#') {
            content = document.querySelector(el.getAttribute('data-content')).innerHTML;
        } else {
            content = el.parentNode.querySelector(el.getAttribute('data-content')).innerHTML;
        }
        let attributes = el.getAttribute('data-analytics').split(/,(.+)/);
        let events = [JSON.parse(attributes[0]), JSON.parse(attributes[1] || '{}')];
        plausible(...events);
        F.Popin.show(
            el.getAttribute('data-title'),
            content,
            true,
            e.currentTarget.getAttribute('data-type'),
            [],
            el.hasAttribute('data-hide-close-btn')
        );
        return;
    }

    if(e.currentTarget.getAttribute('data-trigger-component') && e.currentTarget.getAttribute('data-trigger-action')) {
        F[e.currentTarget.getAttribute('data-trigger-component')][e.currentTarget.getAttribute('data-trigger-action')]();
    }

});

/**
 * utilities
 */

const $html = document.querySelector('html');

const scrollbarWidth = getScrollbarWidth();
F.lockScroll = () => {
    document.body.style.paddingRight = scrollbarWidth+'px';
    //F.Sticky.$el.style.paddingRight = scrollbarWidth+'px';
    $html.classList.add('lock-scroll');

};

F.unlockScroll = () => {
    //F.Sticky.$el.style.paddingRight = '';
    document.body.style.paddingRight = '';
    $html.classList.remove('lock-scroll');
};

document.body.addEventListener('keyup', e => {
    if ((e.key && e.key === 'Tab') || (e.keyCode && e.keyCode === 9)) {
        $html.classList.add('a11y');
    }
});

window.addEventListener('mousemove', () => {
    $html.classList.remove('a11y');
});

// Displays the contact form if 'contact' anchor is defined
let hash = window.location.hash
if (window.location.hash == '#contact') {
    let button = document.querySelector('.contact-form-button')
    if (button) button.click()
}
