import { AComponent } from "../abstract/AComponent";

export class CharCounter extends AComponent {
  initialize(params) {
    this.events = {
      'input .counter-input' : 'updateCounter'
    }

    this.$input = '.counter-input'
    this.$view = '.counter-view'

    super.initialize(params)
  }

  bind() {
    super.bind()

    this.updateCounter()
  }

  updateCounter() {
    let charLength = parseInt(this.$input.value.length)
    this.$view.innerHTML = `${charLength} / ${this.limit}`
    
    if (charLength > this.limit) {
      this.$view.classList.add("counter-exceeded")
    } else {
      this.$view.classList.remove("counter-exceeded")
    }
  }
}
