import { AComponent } from "../abstract/AComponent";

export class Cases extends AComponent {
    initialize(params) {
        this.events = {
            'click .show-verso' : 'showVerso',
            'click .close' : 'closeVerso',
            'click .see-more' : 'seeMore'
        }

        super.initialize(params);
    }

    showVerso(e) {
        e.preventDefault();
        this.$verso.classList.add('show');
    }

    closeVerso(e) {
        e.preventDefault();
        this.$verso.classList.remove('show');
    }

    seeMore(e) {
        e.preventDefault();
        this.$el.classList.remove('summarize');
        e.target.parentNode.removeChild(e.target);
    }
}
