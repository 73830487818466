import { ATogglableComponent } from "../abstract/ATogglableComponent";

export class Menu extends ATogglableComponent {
    initialize(params) {
        super.initialize(params)
        //this.lockScroll = true
    }

    render() {
        super.render()
        this.setCurrentItem()
    }

    show() {
        super.show()
        this.displayFlash(false)
        this.trigger('show')
    }

    hide() {
        super.hide()
        this.displayFlash(true)
        this.trigger('hide')
    }

    displayFlash(show) {
        if (document.body.classList.contains('have-flash')) {
            for (const flashEl of document.getElementsByClassName("flash")) {
                flashEl.style.display = show ? null : 'none'
            }
        }
    }

    setCurrentItem() {
        const currentPath = window.location.pathname
        const currentMenuItem = Array.
            from(this.$el.querySelectorAll("li[data-paths]")).
            find(element => element.dataset.paths.split(',').includes(currentPath))

        if (currentMenuItem) currentMenuItem.classList.add('current')
    }
}
