import { ATogglableComponent } from "../abstract/ATogglableComponent";

export class StickyHeader extends ATogglableComponent {
    initialize(params) {
        this.isFixed = false;
        this.distanceToAppear = this.distanceToAppear || 50;
        this.isLazy = true;

        this.forceShow = false;
        super.initialize(params);


    }
    render() {
        if(super.render() !== false) {
            //non focusable on keyboards
            this.$el.querySelectorAll('a,button,input').forEach($el => {
                $el.setAttribute('tabindex', -1);
            });
            this.burger = this.$el.querySelector('.burger-ico')
        }
    }
    getHeight() {
        return parseInt(this.$el.offsetHeight, 10);
    }
    bind() {
        this.scroll(F._.scrollTop, F._.scrollTop - 1);
        super.bind();


        this.showMenu = this.showMenu.bind(this);
        this.hideMenu = this.hideMenu.bind(this);
        F.Menu.on('show', this.showMenu,true);
        F.Menu.on('hide', this.hideMenu,true);

    }
    scroll (scrollTop, lastScrollTop) {
        super.scroll(scrollTop, lastScrollTop);

        if(this.forceShow) return;

        if (scrollTop >= this.distanceToAppear && !this.isOpen) {
            this.show();
        } else if (scrollTop < this.distanceToAppear && this.isOpen) {
            this.hide();
        }
    }

    showMenu() {
        if (this.burger) this.burger.classList.add('open')
    }
    hideMenu() {
        if (this.burger) this.burger.classList.remove('open')
    }
}
