import { AComponent } from "../abstract/AComponent";

export class Flash extends AComponent {
    initialize(params) {
        this.events = {
            'click .close': 'close'
        }
        super.initialize(params)
    }

    close(e) {
        e.stopPropagation()
        e.preventDefault()
        document.body.classList.remove('have-flash')
    }
}
