import { AComponent } from "../abstract/AComponent";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import {AScrollableElmt} from "../abstract/AScrollableElmt";

let deco_id = 1;
export class Deco extends AScrollableElmt {
    initialize(params) {
        this.$routePath = '.routepath';
        this.$routevoiturebleu = '.routevoiturebleu';
        this.$routevoiturerose = '.routevoiturerose';
        this.$routepin = '.routepin';

        super.initialize(params);
    }

    bind() {
        super.bind();

        this.id = 'instance_'+deco_id++;

        /* il reprend les voitures du haut pour les mettre en bas ! */

        var tween1 = gsap.to(this.$routevoiturebleu, {
                motionPath: {
                    path: this.$routePath,
                    align:  this.$routePath,
                    autoRotate:true,
                    alignOrigin: [0.5, 0.5],
                    start:0.1,
                    end:0.5
                },
                immediateRender:true,
                duration:1, ease:'none',
                scrollTrigger: {
                    trigger: this.$el,
                    scrub:0.3,
                    start: "top bottom",
                    end: "bottom top"
                }
            });

        var tween2 = gsap.to(this.$routevoiturerose, {
            motionPath: {
                path: this.$routePath,
                align:  this.$routePath,
                autoRotate:true,
                alignOrigin: [0.5, 0.5],
                start:0.3,
                end:0.8
            },
            immediateRender:true,
            duration:1, ease:'none',
            scrollTrigger: {
                trigger: this.$el,
                scrub:0.3,
                start: "top bottom",
                end: "bottom top"
            }
        });

        var tween3 = gsap.to(this.$routepin, {
            motionPath: {
                path: this.$routePath,
                align:  this.$routePath,
                alignOrigin: [0.5, 1],
                start:0.8,
                end:0.8
            },
            immediateRender:true,
            duration:1, ease:'none',
            scrollTrigger: {
                trigger: this.$el,
                scrub:0.3,
                start: "top bottom",
                end: "bottom top"
            }
        });


    }


    cleanTween() {
        if(this.tween) {
            this.tween.kill(true);
        }
        if(ScrollTrigger.getById(this.id)) {
            ScrollTrigger.getById(this.id).kill(true);
        }
        gsap.set(this.$el, {clearProps: true});
    }
    unbind() {
        super.bind();
        this.cleanTween();
    }



}
