function initMap(elem) {
  return new google.maps.Map(elem, {
    disableDefaultUI: true,
    mapTypeId: google.maps.MapTypeId.ROADMAP
  })
}

function drawPolyline(map, points, strokeColor) {
  const path = points.map(([lat, lng]) => new google.maps.LatLng(lat, lng))
  const polyline = new google.maps.Polyline({
    geodesic: true,
    map,
    path,
    strokeColor,
    strokeWeight: 5
  })

  return polyline
}

function addMarker(map, position, label, outerColor, innerColor) {
  const svg = `<?xml version="1.0"?>
    <svg viewBox="0 0 61 56" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g>
        <rect x="0" y="0" width="61" height="27" rx="8" fill="#ff5526"></rect>
        <text x="50%"
              y="13.5"
              font-family="Gilroy, sans-serif"
              font-size="15"
              fill="#ffffff"
              text-anchor="middle"
              dominant-baseline="middle">
          ${label}
        </text>
        <circle fill="${outerColor}" cx="50%" cy="45" r="11"></circle>
        <circle fill="${innerColor}" cx="50%" cy="45" r="6"></circle>
      </g>
    </svg>`

  return new google.maps.Marker({
    icon: {
      anchor: new google.maps.Point(30.5, 50),
      scaledSize: new google.maps.Size(61, 61),
      url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(svg)
    },
    map,
    position,
    zIndex: 100
  })
}

function zoomToObject(map, object) {
  var bounds = new google.maps.LatLngBounds()
  object.getPath().getArray().forEach((point) => bounds.extend(point))

  map.fitBounds(bounds)
}

document.getElementsByClassName("gmap").forEach(function (elem) {
  var map = initMap(elem)
  var points = JSON.parse(elem.dataset.points)
  var polyline = drawPolyline(map, points, "#FF6625")
  var path = polyline.getPath()

  var departPoint = path.getAt(0)
  var arrivalPoint = path.getAt(path.getLength() - 1)

  addMarker(map, departPoint, "Départ", "#FF6625", "#FFFFFF")
  addMarker(map, arrivalPoint, "Arrivée", "#FFFFFF", "#FF6625")
  zoomToObject(map, polyline)
})
