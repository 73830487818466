import {AScrollableElmt} from '../abstract/AScrollableElmt'

/**
 * Load images lazily and show them on scroll event. You can
 * pass an `autoload` option if you prefer not to wait for the
 * scroll event.
 */
export class LazyImage extends AScrollableElmt {
    initialize(params) {
        this.deltaWillAppear = 400
        super.initialize(params)

        this.$bkg = '[data-bkg-desktop]'
        this.src = null
        this.isLoaded = false
        this.isLoading = false
        this.trigger_event = 'scroll'
    }
    render() {
        super.render()

        if(this.$bkg) {
            this.src = this.$bkg.getAttribute('data-bkg-desktop')
            this.src2x = this.$bkg.getAttribute('data-bkg-desktop-2x')
        } else if(this.$el.getAttribute('data-bkg-desktop')) {
            this.$bkg = this.$el
            this.src = this.$el.getAttribute('data-bkg-desktop')
            this.src2x = this.$el.getAttribute('data-bkg-desktop-2x')
        }

        if (F.isMobile() && this.$bkg && this.$bkg.getAttribute('data-bkg-mobile')) {
            this.src = this.$bkg.getAttribute('data-bkg-mobile')
            this.src2x = this.$bkg.getAttribute('data-bkg-mobile-2x')
        }

        if (this.autoload) this._load();
    }
    willAppearInScroll() {
        super.willAppearInScroll()

        if(this.trigger_event === 'scroll') {
            this._load()
        }
    }
    _load() {
        if (this.src && !this.isLoading && !this.isLoaded) {
            this.isLoading = true
            window.requestIdleCallback(() => {
                this.image = new Image()
                if (this.src2x && 'srcset' in this.image) {
                    this.image.srcset = `${this.src} 1x, ${this.src2x} 2x`
                } else {
                    this.image.src = this.src
                }

                this.ready = this.ready.bind(this)
                if(this.image.complete) {
                    this.ready()
                } else {
                    this.image.onload = this.ready
                }
            })
        }
    }
    ready() {
        window.requestIdleCallback(this._ready.bind(this))
    }
    _ready() {
        this.isLoading = false
        this.isLoaded = true

        this.$bkg.style.backgroundImage = `url("${this._imageSrc}")`
        setTimeout(() => {
            this.$el.classList.add('loaded')
            this.$bkg && this.$bkg.classList.add('loaded')
        }, 50)
    }
    // IE 11 Support
    get _imageSrc() {
        return 'currentSrc' in this.image ?  this.image.currentSrc : this.image.src
    }
}
