import './vendor/modernizr/modernizr.js';

let lastTime = 0;
let vendors = ['ms', 'moz', 'webkit', 'o'];
for(let x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
window.requestAnimationFrame = window[vendors[x]+'RequestAnimationFrame'];
window.cancelAnimationFrame = window[vendors[x]+'CancelAnimationFrame']
    || window[vendors[x]+'CancelRequestAnimationFrame'];
}

if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = (callback, element) => {
        let currTime = new Date().getTime();
        let timeToCall = Math.max(0, 16 - (currTime - lastTime));
        let id = window.setTimeout(() => {
                callback(currTime + timeToCall);
            },
            timeToCall);
        lastTime = currTime + timeToCall;
        return id;
    };
}

if (!window.cancelAnimationFrame) {
    window.cancelAnimationFrame = function(id) {
        clearTimeout(id);
    };
}

const ua = (navigator.userAgent || navigator.vendor || window.opera).toLowerCase();

Modernizr.addTest('ipad', () => {
    return !!ua.match(/ipad/i);
});

Modernizr.addTest('iphone', () => {
    return !!ua.match(/iphone/i);
});

Modernizr.addTest('ipod', () => {
    return !!ua.match(/ipod/i);
});

Modernizr.addTest('ios', () => {
    return (Modernizr.ipad || Modernizr.ipod || Modernizr.iphone);
});

Modernizr.addTest('android', () => {
    return detect("android");
});

Modernizr.addTest('touch', () => {
    return Modernizr.touchevents;
});

Modernizr.addTest('chrome', () => {
    return !Modernizr.ie && detect('chrome');
});

Modernizr.addTest('safari', () => {
    return !Modernizr.ie && !Modernizr.chrome && detect('safari');
});

Modernizr.addTest('ff', () => {
    return typeof InstallTrigger !== 'undefined';
});

Modernizr.addTest('ie', () => {
    return detect('msie') || (detect('trident') && detect('rv:')) || (detect('windows') && detect('edge'));
});

Modernizr.addTest('webviewMessenger', function() {
    return detect(['FBAN', 'FBAV']) && ua.indexOf('MESSENGER');
});

Modernizr.addTest('webviewFacebook', function() {
    return detect(['FBAN', 'FBAV']);
});


const version = getVersion();
if(version) {
    F.browser_version = version;
    document.documentElement.className += ' version-' + version;
}


function getVersion() {
    try {
        if (Modernizr.chrome) {
            return Number(ua.split("chrome/")[1].split(".")[0]);
        }

        if (Modernizr.firefox) {
            return Number(ua.split("firefox/")[1].split(".")[0]);
        }

        if (Modernizr.safari) {
            let v = null;
            if(Modernizr.ios) {
                v = parseFloat(('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(ua) || [0, ''])[1]).replace('undefined', '3_2').replace('_', '.').replace('_', '')) || false;
            } else {
                v = parseFloat(ua.match(/version\/([\.\d]+)/i)[1]);
            }

            return Math.floor(v);
        }

        if (Modernizr.ie) {
            if (detect('msie')) {
                return Number(ua.split("msie ")[1].split(".")[0]);
            }
            if (detect('rv:')) {
                return Number(ua.split("rv:")[1].split(".")[0]);
            }
            //return Number(ua.split("edge/")[1].split(".")[0]);
            return 'edge';
        }
    } catch (e) {
        return false;
    }
}

function detect(array) {
    if (typeof array === "string") { array = [ array ]; }
    for (let i = 0; i < array.length; i++) {
        let v = array[i].toLowerCase();
        if (ua.indexOf(v) !== -1) {
            return true;
        }
    }
    return false;
}
