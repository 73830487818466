import {AComponent} from "../abstract/AComponent";
import {getScrollbarWidth} from "../utils/getScrollbarWidth";
import {getContainSize} from "../utils/getContainSize";

const MARKUP = `<div class="popin-click-overlay full"></div>
    <div class="popin-size">
        <div class="popin-wrapper">
            <div class="popin-scroll-wrapper">
                <a href="#" class="popin-close">
                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip_popin)">
                    <path opacity="0.5" d="M28.3537 23.8605C29.3647 24.8676 29.8703 25.3711 29.8953 25.9938C29.897 26.0363 29.897 26.0788 29.8953 26.1213C29.8703 26.7441 29.3648 27.2476 28.3537 28.2546C27.3426 29.2617 26.8371 29.7652 26.2118 29.7901C26.1692 29.7918 26.1265 29.7918 26.0838 29.7901C25.4586 29.7652 24.953 29.2617 23.942 28.2546L9.45154 13.8221C8.43974 12.8144 7.93385 12.3105 7.90943 11.6872C7.9078 11.6458 7.9078 11.6044 7.90943 11.563C7.93385 10.9397 8.43974 10.4358 9.45154 9.42804C10.4633 8.42029 10.9692 7.91641 11.595 7.89209C11.6366 7.89047 11.6782 7.89047 11.7198 7.89209C12.3456 7.91641 12.8515 8.42029 13.8633 9.42804L28.3537 23.8605Z" fill="white"/>
                    <path opacity="0.5" d="M28.3693 13.8071C29.3803 12.8001 29.8859 12.2966 29.9109 11.6738C29.9126 11.6313 29.9126 11.5888 29.9109 11.5463C29.8859 10.9235 29.3804 10.42 28.3693 9.413C27.3583 8.40595 26.8527 7.90243 26.2275 7.8775C26.1848 7.8758 26.1421 7.8758 26.0995 7.8775C25.4742 7.90242 24.9686 8.40593 23.9576 9.41297L9.45216 23.8604C8.44036 24.8682 7.93447 25.3721 7.91005 25.9954C7.90842 26.0367 7.90842 26.0782 7.91005 26.1196C7.93447 26.7429 8.44036 27.2468 9.45216 28.2545C10.464 29.2623 10.9698 29.7661 11.5956 29.7905C11.6372 29.7921 11.6788 29.7921 11.7204 29.7905C12.3462 29.7661 12.8521 29.2623 13.8639 28.2545L28.3693 13.8071Z" fill="white"/>
                    <path d="M14.4902 18.8416L18.902 23.2356L23.3137 18.8416L18.902 14.4475L14.4902 18.8416Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip_popin">
                    <rect width="26.6995" height="26.6783" fill="white" transform="matrix(0.708521 -0.70569 0.708521 0.70569 0 18.8416)"/>
                    </clipPath>
                    </defs>
                    </svg>
                </a>
                <div class="popin-inner">
                    <h3 class="popin-title"></h3>
                    <div class="popin-content"></div>
                </div>
            </div>
        </div>
    </div>`;

export class Popin extends AComponent {
    initialize(params) {

        this.events = {
            'click .popin-close' : 'onClickClose',
            'click .popin-click-overlay' : 'onClickOverlay'
        };

        this.type = '';
        this.$title = '.popin-title';
        this.$size = '.popin-size';
        this.$content = '.popin-content';
        this.$close = '.popin-close';
        this.$wrapper = '.popin-wrapper';

        super.initialize(params);
    }
    onClickOverlay(e) {
        if(this.canClose) {
            e.stopPropagation();
            e.preventDefault();
            this.hide();
        }
    }
    setScrollY(scrollY) {
        this.$wrapper.scrollTo(0, scrollY);
    }
    onClickClose(e) {
        if(this.canClose) {
            e.stopPropagation();
            e.preventDefault();
            this.hide();
        }
    }
    render() {
        this.$el.innerHTML = MARKUP;
        super.render();
    }
    bind() {
        super.bind();
        this.onKeyDown = this.onKeyDown.bind(this);
    }
    resize(wW, wH) {
        super.resize(wW, wH);

        if(!F.isMobile()) {
            this.$size.style.maxHeight = (wH * .98) + 'px';
        }

        if(this.type === 'video') {
            let bounds = this.$size.getBoundingClientRect();

            let paddingX = 50;
            let paddingY = 50;
            if (F.isMobile()) {
                paddingX = 20;
                paddingY = 35; //(50 + 20) / 2
            }
            let boundsWrapper = getContainSize(1280, 720, bounds.width - paddingX * 2, bounds.height - paddingY * 2);

            boundsWrapper.width += paddingX * 2;
            boundsWrapper.height += paddingY * 2;

            ['left', 'top', 'width', 'height'].forEach(props => {
                this.$wrapper.style[props] = boundsWrapper[props] + 'px';
            });
        } else {
            ['left', 'top', 'width', 'height'].forEach(props => {
                this.$wrapper.style[props] = '';
            });
        }
    }
    show (title = '', content, canClose = true, type = 'html', classes = [], hideCloseBtn = fase) {
        this.type = type;
        this.resize(F._.wW, F._.wH);
        this.canClose = canClose;
        this.injectContentHTML(content || '');
        this.$title.innerHTML = title || '';

        classes.push('popin-overlay--'+this.type);

        this.$el.classList.add(...classes);

        if(title && title.length) {
            this.$title.style.display = 'block';
        } else {
            this.$title.style.display = 'none';
        }

        if(!hideCloseBtn && this.canClose) {
            this.$close.style.display = 'block';
        } else {
            this.$close.style.display = 'none';
        }

        document.body.style.paddingRight = getScrollbarWidth()+'px';
        document.body.style.overflow = 'hidden';
        document.getElementsByTagName('html')[0].style.overflow = 'hidden';

        this.$wrapper.scrollTop = 0;
        this.$$components = '[data-component]';
        this.performSelectors();


        this.componize();

        this.bind();
        this.scroll(F._.scrollTop, F._.scrollTop - 1);
        this.isShown = true;
        this.$el.classList.add('show');
        this.trigger('show');
        document.addEventListener('keydown', this.onKeyDown);
    }
    injectContentHTML(content) {
        this.$content.innerHTML = content.replace(/<\\\/script>/gim, '</script>');
        Array.from(this.$content.querySelectorAll('script')).forEach(script => {
            let clonedScript = document.createElement('script');
            script.attributes.forEach(attribute => {
                clonedScript.setAttribute(attribute.name, attribute.value);
            });
            clonedScript.innerHTML = script.innerHTML;
            setTimeout(() => {
                document.body.appendChild(clonedScript);
            });
        });
    }
    onKeyDown(e) {
        if(e.keyCode === 27 && this.canClose) {
            this.hide();
        }
    }
    hide () {

        this.isShown = false;
        this.unbind();
        this.$el.classList.remove('show');
        document.removeEventListener('keydown', this.onKeyDown);
        setTimeout(() => {
            document.body.style.paddingRight = '';
            document.body.style.overflow = '';
            document.getElementsByTagName('html')[0].style.overflow = '';
            this.subComponents = [];
            this.$content.innerHTML = '';

            this.$el.className = 'popin-overlay'
            this.trigger('hide');
        }, 300);
    }
}
