/**
 * @todo
 *  DONE faire un truc comme $refs dans vue.js pour récuperer l'instance
 *  DONE - automatiser le passage des options
 *  - pouvoir récuperer les datas du parent simplement (comme props dans vue.js en gros)
 *  - finir le progress
 *  - est ce qu'on passerait meme pas ca dans le ALoadableView... LOL
 */
import {ALoadableView} from "./ALoadableView";
import {ucfirst} from "../utils/ucfirst";

export class AComponent extends ALoadableView {

    initialize(params) {
        this.$$components = '[data-component]';
        this.subComponents = null;
        this._loadingQueue  = null;
        this.firstAppearance  = true;
        this.$refs  = null;

        this.isLazy = this.isLazy || false;
        this.isAwake = false;

        super.initialize(params);
    }
    render () {
        this.subComponents = [];
        this._loadingQueue = [];
        this.$refs = {};

        if(this.isLazy && !this.isAwake) return false;

        super.render();
        this.componize();

        if (this.isLoaded) {
            this.isLoaded = this._loadingQueue.length === 0;
        }

        for (let key in this.$refs) {
            if (!(ucfirst(key) in F)) {
                F[ucfirst(key)] = this.$refs[key];
            }
        }

        return this;
    }
    componize() {
        for (let i = 0, $component = null; $component = this.$$components[i]; i++) {
            let name = $component.getAttribute('data-component'),
                options = $component.getAttribute('data-component-options') || '{}',
                condition = $component.getAttribute('data-component-if') || null,
                ifCondition = true,
                ref = $component.getAttribute('data-ref') || null,
                componentElse = $component.getAttribute('data-component-else') || null;

            if (!$component.getAttribute('data-component_instantiated')) {
                options = JSON.parse(options);
                options.el = $component;

                // Retrieve unless condition is exists
                if (!condition) {
                    condition = $component.getAttribute('data-component-unless') || null
                    ifCondition = false
                }

                if (condition) {
                    /*
                        Here originally was an eval, I've changed it into a function that must be in
                        `F`, see `w.F` in the code.
                    */
                    if (F[condition]() !== ifCondition) {
                        if(componentElse) {
                            name = componentElse;
                        } else {
                            continue;
                        }
                    }
                }

                if (!F.components[name]) {
                    throw 'component ' + name + ' needed during render but not loaded, see import.js file';
                    continue;
                }
                let sub = new F.components[name](options);
                sub.render();
                $component.setAttribute('data-component_instantiated', true);
                if (ref) {
                    if (this.$refs[ref] && !Array.isArray(this.$refs[ref])) {
                        this.$refs[ref] = [this.$refs[ref]];
                    }

                    if (this.$refs[ref]) {
                        this.$refs[ref].push(sub)
                    } else {
                        this.$refs[ref] = sub;
                    }
                }
                if (!sub.isLoaded) {
                    this._loadingQueue.push(sub);
                }
                this.subComponents.push(sub);
            }
        }
    }
    wakeup(cb) {
        if(this.isAwake || !this.isLazy) return;
        this.isAwake = true;
        this.unbind();

        this.wakeupMarkup(() => {

            this.render();
            this.bind();
            this.willAppear();
            this.didAppear();

            cb && setTimeout(cb, 100);
        });
    }
    wakeupMarkup(cb) {
        let $script = this.$el.querySelector('script[type="text/template"]');

        if($script) {
            this.$el.innerHTML = $script.innerHTML;
        }
        cb && cb();
    }
    eachSubComponents (cb) {
        let i = this.subComponents.length;
        while (i--) {
            cb.call(this, this.subComponents[i], i);
        }
    }
    bind () {
        super.bind();
        this.onSubComponentEvent = this.onSubComponentEvent.bind(this);

        this.eachSubComponents(component => {
            component.bind();
            component.on('*', this.onSubComponentEvent);
        });
    }
    onSubComponentEvent(event) {
        if(event && event.bubbles && !event.stopPropagation) {
            this.trigger(event.eventName, event);
        }
    }
    unbind () {
        super.unbind();

        this.eachSubComponents(component => {
            component.unbind();
            component.off('*', this.onSubComponentEvent);
        });
    }
    scroll (scrollTop, lastScrollTop) {
        this.eachSubComponents(component => {
            component.scroll && component.scroll(scrollTop, lastScrollTop);
        });
    }
    load () {
        this.loadSubComponents();
    }
    loadSubComponents () {
        if (this._loadingQueue.length) {
            this.loadSubComponent(0);
        } else {
            this.ready();
        }
    }
    loadSubComponent (index) {
        let sub = this._loadingQueue[index];
        this.onSubComponentProgress = this.onSubComponentProgress.bind(this);
        this.onSubComponentReady = this.onSubComponentReady.bind(this);

        sub.on('progress', this.onSubComponentProgress);
        sub.on('ready', this.onSubComponentReady);

        sub.load();
    }
    onSubComponentProgress (event, component) {

    }
    onSubComponentReady (component) {
        component.off('progress', this.onChildProgress);
        component.off('ready', this.onChildReady);
        //this.startPercen+= Math.max(0, Math.min(child.percenPounds || 100));

        let currentIndex = -2;
        for (let i = 0, loadableChild = null; loadableChild = this._loadingQueue[i]; i++) {
            if (loadableChild === component) {
                currentIndex = i;
                break;
            }
        }

        if (this._loadingQueue[currentIndex + 1]) {
            this.loadSubComponent(currentIndex + 1);
        } else {
            this.ready()
        }
    }
    willAppear () {
        this.eachSubComponents( component => {
            component.willAppear();
        });

        if (this.firstAppearance === true) {
            this.willAppearForFirstTime();
        }
    }
    willDisappear () {
        this.eachSubComponents(component => {
            component.willDisappear();
        });
    }
    willAppearForFirstTime () {
        this.eachSubComponents(component => {
            component.willAppearForFirstTime();
        });
    }
    didAppearForFirstTime () {
        this.eachSubComponents(component => {
            component.didAppearForFirstTime();
        });

    }
    didAppear () {
        this.eachSubComponents(component => {
            component.didAppear();
        });

        if (this.firstAppearance === true) {
            this.didAppearForFirstTime();
            this.firstAppearance = false;
        }
    }
    didDisappear () {
        this.eachSubComponents(component => {
            component.didDisappear();
        });
    }
}
