
import { ASlider } from "../abstract/ASlider";
import {setTransform} from "../utils/setTransform";

export class Slider extends ASlider {
    initialize(params) {

        this.bullets = true;
        this.arrowsNavigation = true;
        this.autoResize = true;
        this.$menu = '.menu';
        this.$$arrow = '.a-slider-arrow';
        this.$$menu = '.menu > a, .menu > span';
        this.type = 'image';


        super.initialize(params);

        this.events['click .menu a'] = 'onClickBullet';

    }

    bind() {
        super.bind();
        this.goToSlide(0);
    }

    goToSlide (index) {
        let currentIndex = this.currentIndex;

        super.goToSlide(index);

        if(this.$$menu[currentIndex]) {
            this.$$menu[currentIndex].classList.remove('current');
        }
        this.$$menu[index].classList.add('current');

        if(F.isMobile() && this.type !== 'history') {
            //let left = (F._.wW / 4);
            let left = -20;
            let menuindex = 0;

            for(menuindex = 0; menuindex < index; menuindex++) {
                left -= parseInt(this.$$menu[menuindex].offsetWidth);

            }
            setTransform(this.$menu, "translateX("+left+"px)");
        }

        if(this.type === 'history') {
            let left = -70;
            //let left = 0;
            let menuindex = 0;

            for(menuindex = 0; menuindex < index; menuindex++) {
                if(F.isMobile()) {
                    left -= parseInt(this.$$menu[menuindex].offsetWidth) + 4;
                } else {
                    left -= parseInt(this.$$menu[menuindex].offsetWidth) + 6;
                }

            }

            setTransform(this.$menu, "translateX("+left+"px)");

            menuindex = 0;
            for(menuindex = 0; menuindex < this.$$menu.length; menuindex++) {
                let distance = Math.min(4, Math.abs(menuindex - this.currentIndex));
                this.$$menu[menuindex].setAttribute('data-distance', distance);

            }
        }

    }

    renderNav () {
        super.renderNav();

    }


    resize (wW, wH) {
        super.resize(wW, wH);

        if(this.type === 'image') {
            this.$menu.style.top = parseInt(this.$$items[0].querySelector('.image-wrapper').offsetHeight)+'px';
            this.$el.querySelector('.a-slider-arrow--prev').style.top = (parseInt(this.$$items[0].querySelector('.image-wrapper').offsetHeight) - 10)+'px';
            this.$el.querySelector('.a-slider-arrow--next').style.top = (parseInt(this.$$items[0].querySelector('.image-wrapper').offsetHeight) - 10)+'px';
            //this.$prev.style.top = parseInt(this.$$items[0].querySelector('.image-wrapper').offsetHeight)+'px';
            //this.$next.style.top = parseInt(this.$$items[0].querySelector('.image-wrapper').offsetHeight)+'px';
        }

        if(F.isMobile() && this.type !== 'history') {
            let withMenu = 0;
                this.$$menu.forEach($a => {
                    withMenu += parseInt(wW);
                });
            this.$menu.style.width = withMenu + 'px';
        }

        if(this.type === 'history') {
            let withMenu = 70;
            this.$$menu.forEach($a => {
                withMenu += parseInt($a.offsetWidth);
            });
            this.$menu.style.width = withMenu + 'px';
        }

    }

}
