import {AComponent} from "../abstract/AComponent";
import {getScrollbarWidth} from "../utils/getScrollbarWidth";
import {getContainSize} from "../utils/getContainSize";

export class Application extends AComponent {
    initialize(params) {

        this.events = {
            'click .step' : 'onClickEtape'
        };

        this.currentStep = 'step0';
        this.$telephone = '.phone';
        this.$current = '.current';
        this.$etapes = '.steps';

        super.initialize(params);
    }

    onClickEtape(e) {
        e.preventDefault();
        if(this.$current) {
            this.$current.classList.remove('current');
        }
        this.$current =   e.currentTarget;
        this.$current.classList.add('current');
        this.$telephone.classList.remove(this.currentStep);
        this.currentStep = this.$current.getAttribute('data-step');
        this.$telephone.classList.add(this.currentStep);

    }

}
