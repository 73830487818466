import {AComponent} from "../abstract/AComponent";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { setTransform } from "../utils/setTransform";

let parallax_id = 1;
export class Parallax extends AComponent {
    initialize(params) {

        this.from = this.from || null;
        this.to = this.to || null;

        this.start = this.start || null;
        this.end = this.end || null;

        this.type = 'div';

        this.lastConditionValue = true;
        super.initialize(params);

    }

    resize(wW, wH) {
        super.resize(wW, wH);

        if(this.condition) {
            let conditionValue = eval(this.condition)

            if(!conditionValue && this.lastConditionValue) {
                this.destroy();
            }

            this.lastConditionValue = conditionValue;
        }
    }

    destroy() {
        this.cleanTween();
    }

    bind() {
        super.bind();

        this.id = 'instance_'+parallax_id;

        if(this.type === 'bkg') {

            let $img = this.$el.querySelector('.parallaximage');
            setTransform($img,'translateY(100px)');
            //console.log(this.$el, 'ici', innerHeight);
            this.tween = gsap.to($img, {
                y: -100,
                ease: "none",
                scrollTrigger: {
                    id: this.id,
                    trigger: $img,
                    scrub: 0.2,
                    start: 'top 100%',
                    end: 'bottom 0%'
                }
            });
        } else {
            if (!this.from || !this.to || (this.condition && !eval(this.condition))) return;
            this.tween = gsap.fromTo(this.$el,
                this.from,
                Object.assign({
                    scrollTrigger: {
                        id: this.id,
                        scrub: 0.4,
                        start : this.start,
                        end : this.end,
                        trigger: this.$el,
                        markers: false,//F.CONFIG.DEBUG
                    },
                    ease: "none"
                }, this.to)
            );
        }

        parallax_id++;
    }
    cleanTween() {
        if(this.tween) {
            this.tween.kill(true);
        }
        if(ScrollTrigger.getById(this.id)) {
            ScrollTrigger.getById(this.id).kill(true);
        }
        gsap.set(this.$el, {clearProps: true});
    }
    unbind() {
        super.bind();
        this.cleanTween();
    }
}
