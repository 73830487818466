import {AComponent} from '../abstract/AComponent'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

export class Advantages extends AComponent {
    initialize(params) {
        this.events = {
            'submit .natural-form': 'onSubmit'
        }

        this.$$blocAvantages = '.advantages-bloc'
        this.$select1 = '.select1'
        this.$select2 = '.select2'
        this.$button = '.btn'

        super.initialize(params)
    }

    onSubmit(e) {
        e.preventDefault()
        this.showSelectedState()
        this.$button.classList.add('hide')
        this.$select1.addEventListener('change', this.showSelectedState.bind(this))
        this.$select2.addEventListener('change', this.showSelectedState.bind(this))
    }

    showSelectedState() {
        this.$$blocAvantages.forEach($bloc => {
            $bloc.classList.remove('show')
        })

        this.$el.querySelector('.'+this.$select1.value+'-'+this.$select2.value).classList.add('show')
        // This is important to ensure triggers are positionned correctly even if page changes.
        ScrollTrigger.refresh(true)
    }

}
