import {AComponent} from "../abstract/AComponent";
import {getScrollbarWidth} from "../utils/getScrollbarWidth";
import {getContainSize} from "../utils/getContainSize";
import { gsap, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";

export class AnimText extends AComponent {
    initialize(params) {
        this.tween = null;
        this.splitText  = null;
        this.type = 'split';
        super.initialize(params);
    }

    bind() {
        super.bind();

        var display =  window.getComputedStyle(this.$el, null).display;


            let which = this.$el;
            if(this.type === 'split' && !F.isMobile()) {
                this.splitText = new SplitText(this.$el, {type:"lines"});
                which = this.splitText.lines;
            }


            this.tween = gsap.from(which , {
                duration: 0.5,
                opacity:0,
                y:75,
                transformOrigin:"0% 50% -50",
                force3D:true,
                stagger: 0.05,
                ease: "power4.out",
                scrollTrigger: {
                    trigger: this.$el,
                    // This can be used to change the scroll trigger on which we
                    // can see text appear. You may use `markers: true` to debug
                    // triggers.
                    // See https://greensock.com/docs/v3/Plugins/ScrollTrigger
                    start: 'top 95%'
                }
            });






    }
    launchAnim() {
        if(this.tween) this.tween.restart();
    }

    stopAnim() {
        if(this.tween) this.tween.kill();
    }


    cleanTween() {
        if(this.tween) {
            this.tween.kill(true);
        }
        if(ScrollTrigger.getById(this.id)) {
            ScrollTrigger.getById(this.id).kill(true);
        }
        gsap.set(this.$el, {clearProps: true});
    }
    unbind() {
        super.bind();
        this.cleanTween();
    }

}
