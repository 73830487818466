import {AComponent} from "./AComponent";
import { LazyImage } from "../components/LazyImage";
import { isInside } from "../utils/isInside";
import {swipe} from 'pure-swipe';

export class ASlider extends AComponent {
    initialize(params) {

        this.itemsNumberPerSlide = 1;
        this.$$items = '.slider-item';
        this.autoResize = this.autoResize !== undefined ? this.autoResize : false;
        this.currentIndex = -1;
        this.startIndex = 0;
        this.autoPlayTimer = 5;
        this.autoPlayDuration = 5;
        this.arrowsNavigation = this.arrowsNavigation !== undefined ? this.arrowsNavigation : true;
        this.bullets = this.bullets !== undefined ? this.bullets : false;
        this.loop = true;
        this.$$bullets = null;

        super.initialize(params);

        this.events = {
            'click .a-slider-arrow--next' : 'next',
            'click .a-slider-arrow--prev' : 'prev',
            'swiped-left' : 'onSwipedLeft',
            'swiped-right' : 'onSwipedRight'
        };

        if(this.$$bullets && typeof this.$$bullets === 'string') {
            this.events['click '+this.$$bullets] = 'onClickBullet';
        } else {
            this.events['click .a-slider-bullets a'] = 'onClickBullet';
        }
    }
    onSwipedLeft(e) {
        this.next()
    }
    onSwipedRight(e) {
        this.prev()
    }
    onClickBullet (e) {
        e.preventDefault();e.stopPropagation();
        this.goToSlide(parseInt(e.currentTarget.getAttribute('data-index')));
    }
    each (cb) {
        for(let i = 0, $item = null; $item = this.$$items[i]; i++) {
            cb(this.$$items[i], i);
        }
    }
    resize (wW, wH) {
        super.resize(wW, wH);

        if(this.autoResize) {
            //if(!F.isMobile()) {
                let maxHeight = 0;
                this.each($item => {
                    maxHeight = Math.max(maxHeight, parseInt($item.offsetHeight));
                });

                this.$el.style.height = (maxHeight + 20) + 'px';
            //}
        }
    }
    doAutoResize () {
        return;
        /*/if(this.autoResize && this.$$items[this.currentIndex]) {
            this.$el.style.height = parseInt(this.$$items[this.currentIndex].offsetHeight)+'px';
        }*/
    }
    render () {
        super.render();

        this.$el.classList.add('a-slider');
        if(this.autoResize) this.$el.classList.add('auto-resize');

        this.each($item => {
            $item.classList.add('a-slider-item');
        });

        this.prepareSeveralItemsPerSlide();
        this.prepareLazyImages();
        this.renderNav();
    }
    prepareSeveralItemsPerSlide() {
        if(this.itemsNumberPerSlide > 1) {
            let slides = [];
            this.each(($item, index) => {
                if(!(index % this.itemsNumberPerSlide)) {
                    slides.push([]);
                }
                slides[slides.length - 1].push($item);
                $item.setAttribute('data-slider-item-index', slides[slides.length - 1].length);
            });
            this.$$items = slides;
        }
    }
    prepareLazyImages() {
        //pour chaque sous component, on regarde si c'est une LazyImage et si elle est pas dans les 2 premieres slides
        //pour lui dire de ne pas se charger au scroll
        //on la fera charger quand on arrivera dans la slide précédente
        this.eachSubComponents(component => {
            let isNotInsideFirst = true;

            if(component instanceof LazyImage && this.$$items && this.$$items.length) {
                if(Array.isArray(this.$$items[0])) {
                    for(let e = 0; e < 2; e++) {
                        let i = this.$$items[e].length;
                        while (i-- && isNotInsideFirst) {
                            let $item = this.$$items[e][i];
                            isNotInsideFirst = component.$el !== $item && !isInside(component.$el, $item);
                        }
                    }
                } else {
                    isNotInsideFirst = (component.$el !== this.$$items[0] && !isInside(component.$el, this.$$items[0]))
                        && (component.$el !== this.$$items[1] && !isInside(component.$el, this.$$items[1]));
                }

                if(isNotInsideFirst) {
                    component.trigger_event = 'slider';
                }
            }

        });
    }
    renderNav () {
        if(this.$$items.length > 1) {
            if (this.arrowsNavigation) {
                ['next', 'prev'].forEach(nav => {
                    let el = document.createElement('a');
                    el.className = 'a-slider-arrow a-slider-arrow--'+nav;
                    el.href = '#';
                    el.appendChild(document.getElementById('slider_arrow').cloneNode(true));
                    this.$el.appendChild(el);
                });
            }

            if(this.bullets && !this.$$bullets) {
                let $$bulletsWrapper = document.createElement('div');
                $$bulletsWrapper.className = 'a-slider-bullets';

                let html = '';
                this.each(($item, i) => {
                    html+= '<a data-index="'+i+'" href="#" '+(i === this.currentIndex ? 'class="current"' : '')+'>.</a>';
                });

                $$bulletsWrapper.innerHTML = html;
                this.$el.appendChild($$bulletsWrapper);

                this.$$bullets = $$bulletsWrapper.childNodes;
            }
        }
    }
    findNextSlideIndex() {
        return this.$$items[this.currentIndex + 1] ? this.currentIndex + 1 : 0
    }
    findPrevSlideIndex() {
        return this.$$items[this.currentIndex - 1] ? this.currentIndex - 1 : this.$$items.length - 1;
    }
    next (e) {
        if(e) {
            e.preventDefault(); e.stopPropagation();
        }
        if(this.loop) {
            this.goToSlide(this.findNextSlideIndex());
        } else {
            if(this.$$items[this.currentIndex + 1]) {
                this.goToSlide(this.currentIndex + 1);
            } else {
                this.trigger('reached-end');
            }
        }
    }
    prev (e) {
        if(e) {
            e.preventDefault(); e.stopPropagation();
        }
        if(this.loop) {
            this.goToSlide(this.findPrevSlideIndex());
        } else {
            if(this.$$items[this.currentIndex - 1]) {
                this.goToSlide(this.currentIndex - 1);
            } else {
                this.trigger('reached-start');
            }
        }
    }
    goToSlide (index) {
        this.cancelAutoPlay();

        if(index === this.currentIndex) return false;

        if(this.$$items[this.currentIndex]) {
            if(Array.isArray(this.$$items[this.currentIndex])) {
                let i = this.$$items[this.currentIndex].length;
                while(i--) {
                    this.$$items[this.currentIndex][i].classList.remove('current');
                }
            } else {
                this.$$items[this.currentIndex].classList.remove('current');
            }
            if(this.$$bullets && this.$$bullets[this.currentIndex]) {
                this.$$bullets[this.currentIndex].classList.remove('current');
            }
        }

        if(this.$$items[index]) {

            if(Array.isArray(this.$$items[index])) {
                let i = this.$$items[index].length;
                while(i--) {
                    this.$$items[index][i].classList.add('current');
                }
            } else {
                this.$$items[index].classList.add('current');
            }

            if(this.$$bullets && this.$$bullets[index]) {
                this.$$bullets[index].classList.add('current');
            }
        }

        //si y'a des lazyimages dans le slider on charge celle en cours et la suivante
        if(index > 0) this.preloadSlide(index);
        setTimeout(() => {
            this.preloadSlide(index + 1);
        }, 100);

        this.trigger('change', this.currentIndex, index, this.$$items[this.currentIndex] || null, this.$$items[index] || null);

        this.startAutoPlay();
        this.currentIndex = index;
        if(this.autoResize) {
            this.doAutoResize();
        }
    }
    preloadSlide(index) {
        if(this.$$items[index]) {
            if(Array.isArray(this.$$items[index])) {
                let i = this.$$items[index].length;
                while(i--) {
                    this.eachSubComponents(component => {
                        if (component instanceof LazyImage && component.trigger_event === 'slider' && (component.$el === this.$$items[index][i] || isInside(component.$el, this.$$items[index][i]))) {
                            component._load();
                        }
                    });
                }
            } else {
                this.eachSubComponents(component => {
                    if (component instanceof LazyImage && component.trigger_event === 'slider' && (component.$el === this.$$items[index] || isInside(component.$el, this.$$items[index]))) {
                        component._load();
                    }
                });
            }
        }
    }
    cancelAutoPlay () {
        if(this.autoPlayTimer) {
            clearTimeout(this.autoPlayTimer);
            this.autoPlayTimer = null;
        }
    }
    startAutoPlay () {
        this.cancelAutoPlay();

        if(this.autoPlayDuration > 0) {
            this.autoPlayTimer = setTimeout(() => {
                this.next();
            }, this.autoPlayDuration * 1000);
        }
    }
    bind () {
        super.bind();
        if(!this.goToSlide(this.startIndex) && this.autoResize) {
            this.doAutoResize();
        }
        this.startAutoPlay();
    }
    unbind () {
        super.unbind();

        this.goToSlide(-1);

        this.cancelAutoPlay();
    }
}
