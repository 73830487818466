
import {ASlider} from './classes/abstract/ASlider'
import {ATogglableComponent} from './classes/abstract/ATogglableComponent'
import {AnimText} from './classes/components/AnimText'
import {Application} from './classes/components/Application'
import {Advantages} from './classes/components/Advantages'
import {Card} from './classes/components/Card'
import {Cases} from './classes/components/Cases'
import {CharCounter} from './classes/components/CharCounter'
import {CustomSelect} from './classes/components/CustomSelect'
import {Deco} from './classes/components/Deco'
import {Flash} from './classes/components/Flash'
import {Hero} from './classes/components/Hero'
import {LazyImage} from './classes/components/LazyImage'
import {Menu} from './classes/components/Menu'
import {NaturalForm} from './classes/components/NaturalForm'
import {Parallax} from './classes/components/Parallax'
import {Popin} from './classes/components/Popin'
import {Press} from './classes/components/Press'
import {Slider} from './classes/components/Slider'
import {StickyHeader} from './classes/components/StickyHeader'
import {SubMenu} from './classes/components/SubMenu'


F.components = {
    ASlider,
    ATogglableComponent,
    AnimText,
    Application,
    Advantages,
    Card,
    Cases,
    CharCounter,
    CustomSelect,
    Deco,
    Flash,
    Hero,
    LazyImage,
    Menu,
    NaturalForm,
    Parallax,
    Popin,
    Press,
    Slider,
    StickyHeader,
    SubMenu
};
