import {AComponent} from "../abstract/AComponent";

export class SubMenu extends AComponent {
    initialize(params) {
        super.initialize(params);

        this.isOpen = false;
        this.events = {
            'click' : 'showmenu'
        }
    }


    didAppear() {
        super.didAppear();

    }

    showmenu(e) {
        // An actual clickable link and not the current page, use default behaviour.
        if (e.target.tagName === 'A' && !e.target.parentElement.classList.contains('current')) return

        e.preventDefault()
        e.stopPropagation()
        if(this.isOpen) {
            this.$el.classList.remove('open')
            this.isOpen = false;
        } else {
            this.$el.classList.add('open')
            this.isOpen = true;
        }
    }

}
