import {AComponent} from "../abstract/AComponent"

export class Press extends AComponent {
    initialize(params) {

        this.$list = '.list'
        this.$listInner = '.list-inner'
        super.initialize(params)

        this.events = {
            'click .more' : 'onClickSeemore',
        }

        if (this.pagination === undefined)
            throw(`The data-component-option='{"pagination": number}' attribute must be defined in component's tag`)
    }

    onClickSeemore(e) {
        e.preventDefault()
        let numShow = 0
        let $$hide = this.$el.querySelectorAll('.hide')

        let height = this.$list.getBoundingClientRect().height
        this.$list.style.height = height+'px'


        setTimeout(() => {
            height = this.$listInner.getBoundingClientRect().height
            this.$list.style.height = height+'px'
        }, 10)

        for (const hiddenEl of $$hide) {
            if (numShow >= this.pagination)
                break

            hiddenEl.classList.add('willshow')
            setTimeout(() => {
                hiddenEl.classList.add('show')
                hiddenEl.classList.remove('hide')
            }, numShow * 200)
            numShow ++
        }


        setTimeout(() => {
            let $$hide2 = this.$el.querySelectorAll('.hide')
            if($$hide2.length === 0) e.currentTarget.classList.add('hide')
            this.$list.style.height = 'auto'
        }, numShow * 200 + 50)
    }

}
