import {AComponent} from "../abstract/AComponent";
import {delegateEventListener, undelegateEventListener} from "../../vendor/event-delegation";
import {ATogglableComponent} from "../abstract/ATogglableComponent";

const $body = document.body;

export class CustomSelect extends ATogglableComponent {
    initialize(params) {
        this.closeOnOutsideClick = true;

        super.initialize(params);

        this.$$options = 'option';
        this.$select = 'select';
        this.isShown = false;
        this.$selectValue = '.custom-select-value';

        this.maxWidth = null;

        this.value = '';

        this.events = {
            'click .custom-select-value' : 'onClickValue'
        }
    }
    onClickValue(e) {
        e.preventDefault();
        this.show();
    }
    getHref() {
        return this.$$options[this.$select.selectedIndex].getAttribute('data-href');
    }
    show() {
        /*
        this.isShown = true;
        this.$el.classList.add('show');
           */

        super.show();
        this.$popinOverlay.classList.add('show');

        let bounds = this.$el.getBoundingClientRect();

        this.$popin.style.left = (bounds.left) + 'px';
        this.$popin.style.top = (bounds.top + F._.scrollTop) + 'px';

        if(!this.maxWidth) {
            this.maxWidth = Math.max(F._.wW - 40, Math.max(300, bounds.width));
        }
        this.$popin.style.maxWidth = this.maxWidth +'px';
    }
    hide() {
        super.hide();
        /*
        this.isShown = false;
        this.$el.classList.remove('show');
        */
        this.$popinOverlay.classList.remove('show');
    }
    render() {
        super.render();

        this.$popinOverlay = document.createElement('div');
        this.$popinOverlay.classList.add('custom-select-popin-overlay');
        this.$popin = document.createElement('div');
        this.$popin.classList.add('custom-select-popin');

        this.value = this.$select.value;


        let markup = '<div class="custom-select-select">';

        this.$$options.forEach(o => {
            let val = o.getAttribute('value');
            markup+= '<span class="custom-select-option-item '+(this.value === val ? 'selected' : '')+'" data-option="'+val+'">'+o.innerText+'</span>';
        });

        this.$popin.innerHTML = markup+'</div>';

        this.$popinOverlay.appendChild(this.$popin);

        this.$$popinOptions = this.$popinOverlay.querySelectorAll('.custom-select-option-item');
        $body.appendChild(this.$popinOverlay);
    }
    onClickOverlay (e) {
        e.preventDefault();
        this.hide();
    }
    onClickOption (e) {
        e.preventDefault();

        let old_val = this.value;
        this.value = e.currentTarget.getAttribute('data-option');

        this.$$popinOptions.forEach(o => {
            o.classList[o.getAttribute('data-option') === this.value ? 'add' : 'remove']('selected');
        });

        // This won't trigger a change event, hence we need to trigger it ourselves.
        // See https://stackoverflow.com/a/2856602/6320039
        this.$select.value = this.value
        if ("createEvent" in document) {
            let event = document.createEvent("HTMLEvents")
            event.initEvent("change", false, true)
            this.$select.dispatchEvent(event)
        } else {
            this.$select.fireEvent("onchange")
        }

        this.$selectValue.innerHTML = this.$$options[this.$select.selectedIndex].innerText;

        if (this.value !== old_val) {
            this.trigger('change', this.value)
        }

        this.hide();
    }

    changeOptionsshow(value) {
        var numOptionShow = 0;
        this.$$popinOptions.forEach(o => {
            if(o.getAttribute('data-option').includes(value)) {
                o.classList.remove('hide');
                if(numOptionShow === 0) {
                    this.$select.value = o.getAttribute('data-option');

                    this.$selectValue.innerHTML = this.$$options[this.$select.selectedIndex].innerText;
                    this.trigger('change', this.value)
                }
                numOptionShow++;
            } else {
                o.classList.add('hide');
            }
        });
    }

    bind() {
        super.bind();

        this.onClickOverlay = this.onClickOverlay.bind(this);
        this.onClickOption = this.onClickOption.bind(this);

        this.$popinOverlay.addEventListener('click', this.onClickOverlay);
        delegateEventListener(this.$popin, 'click', '.custom-select-option-item', this.onClickOption);
    }
    unbind() {
        super.unbind();

        this.$popinOverlay.removeEventListener('click', this.onClickOverlay);
        undelegateEventListener(this.$popin, 'click', '.custom-select-option-item', this.onClickOption);
    }
}
