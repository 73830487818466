import { AComponent } from "../abstract/AComponent";
import { gsap } from "gsap";

export class Hero extends AComponent {
    initialize(params) {

        this.$h1 = 'h1';
        this.$btn = '.btn';
        this.$submenu = '.sub-menu';
        this.$dl = '.download-app';
        this.$img = '.image-big-k';
        this.$route = '.deco-route';
        this.timeline = null;


        this.$bigKHome = '.big-k--headerhome';
        this.$wrapperLarge = '.wrapper--xlarge';

        this.$refs = {
            /**
             * @var AnimText AnimText
             */
            AnimText : null
        };


        super.initialize(params);
    }

    render () {
        super.render();

        if(!Array.isArray(this.$refs.AnimText)) {
            this.$refs.AnimText = this.$refs.AnimText ? [this.$refs.AnimText] : [];
        }
    }

    resize(wW, wH) {
        super.resize(wW, wH);

        if(!F.isMobile()) {
            if(this.$wrapperLarge && this.$img && this.$bigKHome) {
                let widthK = 2373, heightK = 2122;

                const boundsWrapper = this.$wrapperLarge.getBoundingClientRect();
                const boundsK = this.$bigKHome.getBoundingClientRect();

                //comme le K est en background-size: auto 100%, on calcul sa largeur par rapport à sa height 100%
                const realWidthK = boundsK.height * widthK / heightK;
                //on fait commencer l'image de la femme à la moitié du K - le décalage du K (transformX)
                this.$img.style.left = `${- (boundsWrapper.left - boundsK.left) +  realWidthK / 2}px`;
                //en right, on la fait sortir du wrapper jusqu'a la limite droite de l'écran
                this.$img.style.right = `${-boundsWrapper.left}px`;
            }
        }

    }

    bind() {
        super.bind();


        //gsap.to(this.$el, {opacity: 1, duration: 1, delay: 1});

        this.$refs.AnimText.forEach(text => {
            text.stopAnim();
        });

        var tl = gsap.timeline();
        tl.to(this.$el, {opacity: 1, duration: 1, onComplete : () => {
            this.$refs.AnimText.forEach(text => {
                text.launchAnim();
            });
        }});

        if(this.$img) tl.from(this.$img,{opacity: 0, duration: 0.5 });
        if(this.$submenu) tl.to(this.$submenu, {x: 0, duration: 0.5, ease: "power4.out"});
        if(this.$route) tl.from(this.$route, {opacity: 0, duration: 0.5});
        if(this.$btn) tl.from(this.$btn,{opacity: 0, y:20, ease: "power4.out", duration: 0.5 });
        if(this.$dl) tl.from(this.$dl,{opacity: 0, y:20, ease: "power4.out", duration: 0.5 });
    }


    cleanTween() {
        if(this.timeline) {
            this.timeline.kill(true);
        }

        gsap.set(this.$el, {clearProps: true});
    }
    unbind() {
        super.bind();
        this.cleanTween();
    }

}
