// This file is automatically compiled by Webpack, along with any other files
// present in this directory.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import '../src/header'
import '../src/index'

;(function(m, d) {
	if(!m.flexbox) {
		d.body.className += ' error--browser';
		d.write('<div id="error_browser_wrapper"><div id="error_browser">' +
			'<div class="logo"><img src="<%= asset_path("images/logo-klaxit-header.png") %>" alt="Klaxit" /></div>' +
			'<div class="link_navigateur"><p>Ce site n\'est pas optimisé pour votre navigateur internet<br />Vous pouvez le mettre à jour ou utiliser un des navigateurs suivants&nbsp;:</p>' +
			'<ul><li><a target="_blank" rel="noopener noreferrer" href="http://www.google.com/intl/fr_fr/chrome/browser/"><img src="<%= asset_path("images/picto-browser-chrome.png") %>" alt="Chrome"><br/>Chrome</a></li>' +
			'<li><a target="_blank" rel="noopener noreferrer" href="http://support.apple.com/kb/DL1531?viewlocale=fr_FR"><img src="<%= asset_path("images/picto-browser-safari.png") %>" alt="Safari"><br/>Safari</a></li>' +
			'<li><a target="_blank" rel="noopener noreferrer" href="https://www.mozilla.org/fr/firefox/new/"><img src="<%= asset_path("images/picto-browser-firefox.png") %>" alt="Firefox"><br/>Firefox</a></li>' +
			'<li><a target="_blank" rel="noopener noreferrer" href="http://windows.microsoft.com/fr-fr/internet-explorer/ie-11-worldwide-languages"><img src="<%= asset_path("images/picto-browser-ie.png") %>" alt="IE"><br/>Internet Explorer</a></li></ul>' +
			'</div></div></div>');
	}
})(Modernizr, document)
