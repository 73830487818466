// We load minified version because of classes support
// on IE 11.
import '@klaxit/cookie-consent/dist/cookie-consent'

// Inspired by https://stackoverflow.com/a/179514
function deleteAllCookiesExcept(excepts) {
	const cookies = document.cookie.split(";");

	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i];
		const eqPos = cookie.indexOf("=");
		const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		if (excepts.includes(name.trim())) {
			continue
		}
		document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
	}
}

window.addEventListener("load", function () {
	const cookieConsentName = "cookie_consent"
	const CookieConsent = window.CookieConsent
	const cookiesPagePath = document.querySelector('meta[name="cookiesPagePath"]').content
	const cc = new CookieConsent({
		title: "Gérez vos cookies 🍪",
		description: `Pour Klaxit, votre expérience sur notre site est une priorité.
				C'est pourquoi nous utilisons des cookies et autres traceurs pour 
				permettre le fonctionnement technique du site, mesurer son audience et 
				proposer de la publicité personnalisée.
				<br/>
				En cliquant sur “Accepter”, vous consentez à l'utilisation de cookies 
				pour l'ensemble des finalités ci-dessus et énoncées dans notre
				<a href="${cookiesPagePath}">Politique en matière de cookies</a>. Vous 
				pouvez également refuser en cliquant sur "Refuser" ou personnaliser vos 
				préférences en cliquant sur “Paramètre des cookies”. Nous conservons 
				votre choix pendant 6 mois. Vous pouvez changer d'avis ou vous opposer à
				l'utilisation des cookies à tout moment en cliquant sur « Gérer mes 
				cookies » figurant en bas de chaque page.`,
		buttons: {
			acceptAll: "Tout Accepter",
			acceptSelected: "Accepter",
			reject: "Refuser",
			showSettings: "Paramètre des cookies",
			hideSettings: "Masquer",
		},

		categories: {
			essentials: {
				label: "Cookies essentiels",
				description: `Les cookies essentiels sont les cookies strictement
					nécessaires au fonctionnement de notre site et de nos
					services, telles que la connexion à votre compte et la
					sauvegarde de vos préférences de cookies.`,
				checked: true,
				mandatory: true
			},
			analytics: {
				label: "Cookies d'audience",
				description: `Les cookies d’audience nous permettent de comptabiliser
					le nombre de visiteurs et de suivre l'usage du site pour
					améliorer votre expérience.`
			},
			targeting: {
				label: "Cookies de ciblage",
				description: `Les cookies de ciblage nous permettent de vous proposer nos
					services sur les plateformes partenaires.`
			}
		},
		cookie: {
			// Name of the cookie storing the consent state
			name: cookieConsentName,

			// If null, will take `location.hostname` by default
			domain: null,
			// Duration of the consent
			expiryDays: 180,
			// Allow only over HTTPs
			secure: document.location.protol === "https:",
			// See https://developer.mozilla.org/fr/docs/Web/HTTP/Headers/Set-Cookie/SameSite
			sameSite: "Lax",
		}
	})

  cc.on("change", function () {
    handleAnalyticsConsent()
    handleTargetingConsent()
  })

	cc.on("reject", function () {
		deleteAllCookiesExcept(
			[
				cookieConsentName,
				"klaxit_web_session",
			]
		)
	})

	handleAnalyticsConsent()
	handleTargetingConsent()
})
